<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" @ready="modelReady" @Hide="hide">
      <div v-if="showitem" style="position:relative">
        <div class="text-shadow" style="position:absolute;top:-5px;right:0;padding:5px;cursor:pointer;" @click="modelHide()">
          <i class="fa fa-times"></i>
        </div>
        <div v-if="showitem.linkType=='File'">
          <div v-if="showitem.file">
            <div v-if="showitem.file.isImage" class="panoinfo">
              <h5>{{showitem.title}}</h5>
              <img :src="(showitem.file.url||'').replace('/0/0/0/0/', '/600/0/0/0/')" />
              <template v-if="showitem.description">
                <hr />
                <article v-html="showitem.description"></article>
              </template>
            </div>
            <div v-else-if="showitem.file.isAudio" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <audio :src="showitem.file.url" style="width: 100%;" controls controlslist="nodownload" />
              <template v-if="showitem.description">
                <hr />
                <article v-html="showitem.description"></article>
              </template>
            </div>
            <div v-else-if="showitem.file.isVideo" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <video style="width:100%;" :src="showitem.file.url" controls controlslist="nodownload" />
              <template v-if="showitem.description">
                <hr />
                <article v-html="showitem.description"></article>
              </template>
            </div>
            <div v-else-if="showitem.file.type=='Coordinate'" class="panoinfo">
              <h4>{{showitem.title}}</h4>
              <MapView :geojsonUrl="showitem.file.url" :dragable="true" height="200px"></MapView>
              <template v-if="showitem.description">
                <hr />
                <article v-html="showitem.description"></article>
              </template>
            </div>
            <div v-else>

              不支持的类型
              <!--<pre>{{showitem}}</pre>-->
            </div>
          </div>
          <div v-else>

            不支持的类型
            <!--<pre>{{showitem}}</pre>-->
          </div>
        </div>
        <div v-else-if="showitem.linkType=='Text'" class="panoinfo">
          <h4>{{showitem.title}}</h4>
          <hr />
          <article v-html="showitem.description"></article>
        </div>
        <div v-else-if="showitem.linkType=='Link'" class="panoinfo">
          <h4>{{showitem.title}}</h4>
          <p>
            这是个外部链接
          </p>
          <p><a :href="showitem.url" target="_blank">{{showitem.url}}</a></p>
          <div style="height:10px"></div>
          <a :href="showitem.url" target="_blank" style="padding:5px 15px;background-color:#2577e3;color:white;display:block;text-align:center;border-radius:3px">点击此处打开</a>
          <template v-if="showitem.description">
            <hr />
            <article v-html="showitem.description"></article>
          </template>
        </div>
        <div v-else-if="showitem.linkType=='TourGroup'" class="panoBlog">
          <template v-if="group.type=='Gallery'">
          </template>
          <template v-else-if="group.type=='Blog'">
            <TourBlogView :blog="blog"></TourBlogView>
            <div style="position: fixed; top: 0px; right: 0px; background-color: #0008; border-radius: 0 0 0 100px; width: 40px; height: 40px;cursor: pointer;padding:8px 8px 0 0;display:flex;align-items:flex-start;justify-content:flex-end;font-size:16px" @click="hide()">
              <i class="fa fa-times"></i>
            </div>
          </template>
          <template v-else>
            未设置分组
          </template>
        </div>
      </div>
    </component>
    <template v-if="blog">
      <template v-if="group.type=='Gallery'">
        <LightGallery :items="blog.items" :dynamic="true" @Closed="hide()"></LightGallery>
      </template>
    </template>
  </div>
</template>
<script>
  import 'viewerjs/dist/viewer.css'
  import Viewer from 'viewerjs'
  import PanoLeafletMap from '../../Map/PanoLeafletMap'
  import LightGallery from '../../LightGallery'
  import MapView from '../../Map/MapView'
  import TourBlogView from './TourBlogView'
  export default {
    components: {
      PanoLeafletMap,
      LightGallery,
      MapView,
      TourBlogView,
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        showitem: null,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      groups() {
        if (this.publicData.tour) {
          return this.publicData.tour.groups
        } else {
          return []
        }
      },
      group() {
        if (this.showitem && this.showitem.linkType == 'TourGroup') {
          return this.getGroup(this.showitem.linkGroupGUID) || {}
        } else {
          return null
        }
      },
      blog() {
        if (this.showitem && this.showitem.linkType == 'TourGroup') {
          return this.getblog(this.showitem)
        } else {
          return null
        }
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
          hotspotShowView: this.HotspotShowView,
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
          //window.location.href = "#anchor1"
          window.history.pushState({ name: 'hotspotModelShowed' }, 'hotspotModelShowed')
          window.addEventListener("popstate", this.onPopstate)
        } else {
          alert('模态框还没有准备好')
        }
      },
      hide() {
        this.showitem = null
        this.modelHide()
        window.removeEventListener("popstate", this.onPopstate)
      },
      onPopstate(e) {
        console.log(window.location.href, e)
        this.hide()
      },
      HotspotShowView(item) {
        this.showitem = item
        if (item.linkType == 'TourGroup') {
          var group = this.getGroup(item.linkGroupGUID)
          if (group.type == 'Gallery') {
            return
          }
        }
        if (item.linkType == 'File') {
          if (item.file) {
            if (item.file.isImage) {
              var maxOffsetPercentage = 0.9;
              var image = new Image()
              image.src = item.file.url
              image.alt = item.title
              var viewer = new Viewer(image, {
                title: (image, imageData) => {
                  return `${image.alt} (${imageData.naturalWidth} × ${imageData.naturalHeight})`
                },
                hidden: () => {
                  viewer.destroy();
                },
                toolbar: {
                  zoomIn: 1,
                  zoomOut: 1,
                  oneToOne: 1,
                  reset: 1,
                  prev: false,
                  play: false,
                  next: false,
                  rotateLeft: 1,
                  rotateRight: 1,
                  flipHorizontal: 1,
                  flipVertical: 1
                },
                navbar: false,
                move: (event) => {
                  var viewerData = viewer.viewerData;
                  var imageData = viewer.imageData;
                  var maxOffsetHorizontal = viewerData.width * maxOffsetPercentage;
                  var maxOffsetVertical = viewerData.height * maxOffsetPercentage;
                  var detail = event.detail;
                  var left = detail.x;
                  var top = detail.y;
                  var right = viewerData.width - (left + imageData.width);
                  var bottom = viewerData.height - (top + imageData.height);

                  if (
                    // Move left
                    (detail.x < detail.oldX && right > 0 && right > maxOffsetHorizontal)

                    // Move right
                    || (detail.x > detail.oldX && left > 0 && left > maxOffsetHorizontal)

                    // Move up
                    || (detail.y < detail.oldY && bottom > 0 && bottom > maxOffsetVertical)

                    // Move down
                    || (detail.y > detail.oldY && top > 0 && top > maxOffsetVertical)
                  ) {
                    event.preventDefault();
                  }
                },
              });
              viewer.show();
              return
            }
          }
        }
        this.show()
      },
      getblog(item) {
        var groupItems = this.getFunc({ target: 'Data', name: 'getgroup' })(item.linkGroupGUID)
        var group = this.getGroup(item.linkGroupGUID)
        return {
          items: groupItems,
          ...group
        }
      },
      getGroup(groupGuid) {
        for (var i in this.groups) {
          if (this.groups[i].guid == groupGuid) {
            return this.groups[i]
          }
        }
      },

    },
  }
</script>
<style scoped>
  .panoinfo {
    width: 70vw;
    max-width: 600px;
    min-width: 240px;
    color: #fff;
  }

  .panoBlog {
    width: 90vw;
    max-width: 600px;
    min-width: 240px;
    color: #fff;
  }

  .exif {
    font-size: 1.2rem
  }

    .exif tr:first-child {
      white-space: nowrap
    }

    .exif td {
      padding: 0 0 0 0;
      border-bottom: none;
    }

    .exif th {
      padding: 0 0 0 0;
      text-align: right;
      width: 6em;
      vertical-align: top;
      border-bottom: none;
    }
</style>
